import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/add-course",
      name: "add-course",
      component: () => import("@/views/courses pages/add new course.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add Course",
        breadcrumb: [
          {
            text: "Add Course",
            active: true,
          },
        ],
      },
    },

    {
      path: "/courses/:id",
      name: "Edit-course",
      component: () => import("@/views/courses pages/edit.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Edit ",
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-courses",
      name: "list-courses",
      component: () => import("@/views/courses pages/list courses.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All Courses",
        breadcrumb: [
          {
            text: "All Courses",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-categories",
      name: "list-categories",
      component: () => import("@/views/categories pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All Categories",
        breadcrumb: [
          {
            text: "All Categories",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-users",
      name: "list-users",
      component: () => import("@/views/users pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All users",
        breadcrumb: [
          {
            text: "All users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-orders",
      name: "list-orders",
      component: () => import("@/views/orders pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All orders",
        breadcrumb: [
          {
            text: "All orders",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-lessons",
      name: "list-lessons",
      component: () => import("@/views/lessons pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All Lessons",
        breadcrumb: [
          {
            text: "All Lessons",
            active: true,
          },
        ],
      },
    },
    {
      path: "/add-quize",
      name: "add-quize",
      component: () => import("@/views/quizes pages/add.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add quize",
        breadcrumb: [
          {
            text: "Add quize",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-quizes",
      name: "list-quizes",
      component: () => import("@/views/quizes pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All quizes",
        breadcrumb: [
          {
            text: "All quizes",
            active: true,
          },
        ],
      },
    },
    {
      path: "/add-question",
      name: "add-question",
      component: () => import("@/views/questions pages/add.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add question",
        breadcrumb: [
          {
            text: "Add question",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-questions",
      name: "list-questions",
      component: () => import("@/views/questions pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All Questions",
        breadcrumb: [
          {
            text: "All Questions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-answers",
      name: "list-answers",
      component: () => import("@/views/answers pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All Answers",
        breadcrumb: [
          {
            text: "All Answers",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-coupons",
      name: "list-coupons",
      component: () => import("@/views/coupons pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All coupons",
        breadcrumb: [
          {
            text: "All coupons",
            active: true,
          },
        ],
      },
    },
    {
      path: "/list-sections",
      name: "list-sections",
      component: () => import("@/views/sections pages/list.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "All sections",
        breadcrumb: [
          {
            text: "All sections",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth pages/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-forgot-password",
      name: "auth-forgot-password",
      component: () => import("@/views/auth pages/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-reset-password-code",
      name: "auth-reset-password-code",
      component: () => import("@/views/auth pages/ResetPasswordCode.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-reset-password",
      name: "auth-reset-password",
      component: () => import("@/views/auth pages/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/auth pages/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
